<template>
<main id="main" class="site-main site-main-detail" v-for="(detail,index) in details" :key="index">
    <div class="postercard">
        <div class="postercard__img">
            <img :src="urlPath+'uploads/media/blog/'+detail.image">
        </div>
    </div>
    <section class="blog">
        <div class="container">
            <div class="row">
                <div class="col xs-12 sm-8 md-9">
                    <div class="breadcrumb">
                        <ul>
                            <li><router-link :to="{name:'Home'}">Home</router-link></li>
                            <li><router-link :to="{name:'Blog'}">Blog</router-link></li>
                            <li><span class="live">Challening Treks in Nepal</span></li>
                        </ul>
                    </div>
                    <h1 class="folio__title">{{detail.title}}</h1>
                    <div class="reviser" v-html="detail.description">

                    </div>
                </div>
                <div class="col xs-12 sm-4 md-3">
                    <div class="related">
                        <h2 class="related__title"><strong>Related</strong> Article</h2>
                        <article class="latest__post" v-for="blog in relatedblogs" :key="blog[id]">
                            <div class="latest__post-img">
                                <router-link :to="{name:'Blogdetail',params:{slug:blog.slug,title:blog.title}}">
                                    <img :src="urlPath+'uploads/media/blog/thumbnail/440230_'+blog.image" alt="blog.title">
                                </router-link>
                            </div>
                            <div class="latest__post-desc">
                                <time datetime="2011-06-26" class="latest__post-dated">{{formatDate(blog.created_at)}}</time>
                                <h2 class="latest__post-title">
                                    <router-link :to="{name:'Blogdetail',params:{slug:blog.slug}}">{{blog.title}}</router-link>
                                </h2>
                            </div>
                        </article>
                        
                    </div>
                    <div class="related">
                        <h2 class="related__title"><strong>Awesome</strong> Package</h2>
                        <awesome />
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
</template>

<script>
import Awesomepackage from "./Innercomponent/Awesomepackageinner.vue";
import axios from 'axios';
import dayjs from 'dayjs';
export default {
    name: 'Blog Detail',
    data() {
        return {
            details: undefined,
            relatedblogs: undefined,
            apiPath:process.env.VUE_APP_API,
            urlPath:process.env.VUE_APP_PATH,
        }
    },
    mounted() {
        this.getBloginfo(this.$route.params.slug);
        this.getRelatedblogs(this.$route.params.slug);
    },
    methods: {
        getBloginfo(slugurl) {
            axios.get(this.apiPath+'showblog/' + slugurl)
                .then(response => {
                    // console.log(response.data);
                    this.details = response.data;
                    const descEl = document.querySelector('head meta[name="description"]');
                    const keyEl = document.querySelector('head meta[name="keywords"]');
                    const titleEl = document.querySelector('head title');

                    descEl.setAttribute('content', response.data[0]['seo_description']);
                    keyEl.setAttribute('content', response.data[0]['seo_keyword']);
                    titleEl.textContent = response.data[0]['seo_title'] + " :: Nepal Alternative Treks";
                })
        },

        getRelatedblogs(slugurl) {
            axios.get(this.apiPath+'relatedblogs/' + slugurl)
                .then(response => {
                    this.relatedblogs = response.data;
                })
        },
        formatDate(dateString) {
            const date = dayjs(dateString);
            return date.format('DD MMM, YYYY');
        },
    },
    watch: {
        '$route.params.slug'(newSlug) {
            this.getBloginfo(newSlug);
            this.getRelatedblogs(newSlug);
        }
    },
    components: {
        'awesome': Awesomepackage,
    }
}
</script>
